// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails, { $ } from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import {  validate, clean, format, getCheckDigit } from "rut.js"

require("jquery")
import "bootstrap"

// console.log('dv', getCheckDigit('18.972.631'))
// console.log('valid', validate('18.972.631-0'))
// $('#inscription_dni').values();

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// $("#inscription_question_2").attr("disabled", true)
// $("#inscription_question_1").change(function(){
//     var project = $(this).val();
//     if(project == ''){
//       $("#inscription_question_2").attr("disabled", true);
//     }else{
//       $("#inscription_question_2").attr("disabled", false);
//     }
//     $.ajax({
//       url: "/tasks",
//       method: "GET",  
//       dataType: "json",
//       data: {project: project},
//       error: function (xhr, status, error) {
//           console.error('AJAX Error: ' + status + error);
//       },
//       success: function (response) {
//           console.log(response);
//           var tasks = response["tasks"];
//           $("#hour_task_id").empty();

//           $("#hour_task_id").append('<option>Select Task</option>');
//           for(var i = 0; i < tasks.length; i++){
//             $("#hour_task_id").append('<option value="' + tasks[i]["id"] + '">' +tasks[i]["name"] + '</option>');
//           }
//       }
//     });
// });